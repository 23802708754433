import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { "aria-label": "Page navigation example" }
const _hoisted_2 = { class: "pagination justify-content-end" }
const _hoisted_3 = {
  key: 0,
  class: "page-item"
}
const _hoisted_4 = {
  key: 1,
  class: "page-item"
}
const _hoisted_5 = {
  key: 2,
  class: "page-item"
}
const _hoisted_6 = ["onClick", "textContent"]
const _hoisted_7 = {
  key: 3,
  class: "page-item"
}
const _hoisted_8 = {
  key: 4,
  class: "page-item"
}
const _hoisted_9 = {
  key: 5,
  class: "page-item"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("ul", _hoisted_2, [
      (_ctx.props.page != 1)
        ? (_openBlock(), _createElementBlock("li", _hoisted_3, [
            _createElementVNode("a", {
              class: "page-link",
              href: "javascript:void(0)",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.emitPage(1)))
            }, _toDisplayString(_ctx.t('first')), 1)
          ]))
        : _createCommentVNode("", true),
      (_ctx.props.page != 1)
        ? (_openBlock(), _createElementBlock("li", _hoisted_4, [
            _createElementVNode("a", {
              class: "page-link",
              href: "javascript:void(0)",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.emitPage(_ctx.props.page - 1)))
            }, [
              (_ctx.storeX.state.storeCode == 'en')
                ? (_openBlock(), _createBlock(_component_ion_icon, {
                    key: 0,
                    icon: _ctx.icons.chevronBackOutline
                  }, null, 8, ["icon"]))
                : _createCommentVNode("", true),
              (_ctx.storeX.state.storeCode == 'ar')
                ? (_openBlock(), _createBlock(_component_ion_icon, {
                    key: 1,
                    icon: _ctx.icons.chevronForwardOutline
                  }, null, 8, ["icon"]))
                : _createCommentVNode("", true)
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.showPreviousRange)
        ? (_openBlock(), _createElementBlock("li", _hoisted_5, [
            _createElementVNode("a", {
              class: "page-link",
              href: "javascript:void(0)",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.emitPage(_ctx.previousRangeStart)))
            }, " ... ")
          ]))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.props.pages, (n) => {
        return (_openBlock(), _createElementBlock(_Fragment, { key: n }, [
          (_ctx.visibleRange.includes(n))
            ? (_openBlock(), _createElementBlock("li", {
                key: 0,
                class: _normalizeClass(["page-item", { active: n == _ctx.props.page }])
              }, [
                _createElementVNode("a", {
                  class: "page-link",
                  href: "javascript:void(0)",
                  onClick: ($event: any) => (_ctx.emitPage(n)),
                  textContent: _toDisplayString(n)
                }, null, 8, _hoisted_6)
              ], 2))
            : _createCommentVNode("", true)
        ], 64))
      }), 128)),
      (_ctx.showNextRange)
        ? (_openBlock(), _createElementBlock("li", _hoisted_7, [
            _createElementVNode("a", {
              class: "page-link",
              href: "javascript:void(0)",
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.emitPage(_ctx.nextRangeStart)))
            }, " ... ")
          ]))
        : _createCommentVNode("", true),
      (_ctx.props.page != _ctx.props.pages)
        ? (_openBlock(), _createElementBlock("li", _hoisted_8, [
            _createElementVNode("a", {
              class: "page-link",
              href: "javascript:void(0)",
              onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.emitPage(_ctx.props.page + 1)))
            }, [
              (_ctx.storeX.state.storeCode == 'en')
                ? (_openBlock(), _createBlock(_component_ion_icon, {
                    key: 0,
                    icon: _ctx.icons.chevronForwardOutline
                  }, null, 8, ["icon"]))
                : _createCommentVNode("", true),
              (_ctx.storeX.state.storeCode == 'ar')
                ? (_openBlock(), _createBlock(_component_ion_icon, {
                    key: 1,
                    icon: _ctx.icons.chevronBackOutline
                  }, null, 8, ["icon"]))
                : _createCommentVNode("", true)
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.props.pages != _ctx.props.page)
        ? (_openBlock(), _createElementBlock("li", _hoisted_9, [
            _createElementVNode("a", {
              class: "page-link",
              href: "javascript:void(0)",
              onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.emitPage(_ctx.props.pages)))
            }, _toDisplayString(_ctx.t('last')), 1)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}