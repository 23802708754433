
import {
  IonPage,
  IonContent,
} from "@ionic/vue";
import { timeOutline, closeOutline } from "ionicons/icons";
import { defineComponent, ref, reactive } from "vue";
import { useI18n } from "vue-i18n";
import settingsApp from "@/libs/settings";
import httpApp from "@/libs/http";
import { useStore } from "@/store";
import { useRouter } from "vue-router";

import AccountNavComponent from "./AccountNavComponent.vue";
import MainHeader from "@/components/MainHeader.vue";
import MainFooter from "@/components/MainFooter.vue";
import Pagination from "@/components/Pagination.vue";

export default defineComponent({
  name: "WarrantyCertificates",
  setup() {
    const { t, tm } = useI18n();
    const storeX = useStore();
    const router = useRouter();
    const icons = reactive({
      timeOutline,
      closeOutline,
    });
    const warrantyCertificates = ref([]);
    const limit = ref(20);
    const currentPage = ref(1);
    const pages = ref(0);

    const getCustomerCertificates = (page = 1) => {
      currentPage.value = page;
      httpApp
        .sendPostRequest(settingsApp.getEndpointUrl("getCustomerCertificates"), {
          limit: limit.value,
          page: currentPage.value,
        })
        .then(async (response: any) => {
          if (!Object.prototype.hasOwnProperty.call(response, "error")) {
            warrantyCertificates.value = response.items;
            pages.value = response.pager.pages;
          }
        })
        .catch((error) => {
          console.log(error, "error");
        });
    };

    const getUrl = (certificate) => {
      const url =
        "/" + storeX.state.storeCode + "/account/warranty-certificate/" + certificate.entity_id;
      return url;
    };

    const nav = (path, event) => {
      event.preventDefault();
      router.push(path);
    };

    const formateDate = (date: any) => {
      const dateArr = date.split(" ");
      return dateArr[0];
    };

    function ionViewWillEnter() {
      warrantyCertificates.value = [];
      limit.value = 20;
      currentPage.value = 1;
      pages.value = 0;
      getCustomerCertificates();
    }
    return {
      t,
      tm,
      storeX,
      icons,
      warrantyCertificates,
      ionViewWillEnter,
      formateDate,
      getUrl,
      nav,
      pages,
      currentPage,
      getCustomerCertificates,
    };
  },
  components: {
    IonPage,
    IonContent,
    AccountNavComponent,
    MainHeader,
    MainFooter,
    Pagination,
  },
});
