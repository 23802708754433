
import { IonIcon } from "@ionic/vue";
import { defineComponent, ref, computed, onMounted } from "vue";
import { chevronBackOutline, chevronForwardOutline } from "ionicons/icons";
import { useI18n } from "vue-i18n";
import { useStore } from "@/store";
export default defineComponent({
  name: "Pagination",
  props: {
    page: Number,
    pages: Number,
  },
  emits: ["emitPage"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const storeX = useStore();
    const icons = ref({
      chevronBackOutline,
      chevronForwardOutline
    });
    const smallScreen = ref(false);
    const maxCount = ref(10);
    const isSmallScreen = () => {
      const isSmall = window.innerWidth < 768;
      smallScreen.value = isSmall;
      maxCount.value = isSmall ? 3 : 10;
      return isSmall;
    };

    window.addEventListener("resize", () => {
      isSmallScreen();
    });

    const visibleRange = computed(() => {
      const max = Number(maxCount.value);
      let start = props.page - Math.floor(max / 2);
      let end = props.page + Math.floor(max / 2);
      if (start < 1) {
        start = 1;
        end = max;
      }
      if (end > props.pages) {
        start = props.pages - max + 1;
        end = props.pages;
      }
      const range = [];
      for (let i = start; i <= end; i++) {
        range.push(i);
      }
      return range;
    });
    const showPreviousRange = computed(() => {
      return !visibleRange.value.includes(1);
    });
    const showNextRange = computed(() => {
      return !visibleRange.value.includes(props.pages);
    });
    const previousRangeStart = computed(() => {
      const max = Number(maxCount.value);
      let start = props.page - max;
      if (start < 1) {
        start = 1;
      }
      return start;
    });
    const nextRangeStart = computed(() => {
      const max = Number(maxCount.value);
      let start = props.page + max;
      if (start > props.pages) {
        start = props.pages - max;
      }
      return start;
    });
    onMounted(() => {
      isSmallScreen();
    });
    const emitPage = (page) => {
      emit("emitPage", page);
    };
    return {
      props,
      emitPage,
      storeX,
      t,
      icons,
      visibleRange,
      previousRangeStart,
      nextRangeStart,
      showPreviousRange,
      showNextRange
    };
  },
  components: {
    IonIcon
  }
});
